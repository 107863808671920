<template>
    <el-scrollbar class="eCommerce-container">
        <el-breadcrumb separator=">" class="genera-breadcrumb" style="line-height: 42px; margin-left: 20px;">
            <el-breadcrumb-item>商品榜单</el-breadcrumb-item>
            <el-breadcrumb-item>商品详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="goods-info shadow-box">
            <div class="good-img" @click="toGoodDetail(infoDetail.goods_id)">
                <img :src="infoDetail.good_images" alt="">
            </div>
            <div class="info-right text-overflow-2" @click="toGoodDetail(infoDetail.goods_id)">{{infoDetail.good_title}}</div>
        </div>
        <div class="data-content shadow-box">
            <div class="top-bar">
                <span class="text">商品概况</span>
            </div>
            <div class="line-data">
                <div class="line-item">
                    <div class="item-left">
                        <img :src="require('../../../../assets/images/student/good-icon-01.png')" alt="">
                        <div class="text">引流漏斗</div>
                    </div>
                    <div class="item-right">
                        <div class="item">
                            <div class="top">访客指数</div>
                            <div class="text">{{infoDetail.visitor_index}}</div>
                        </div>
                        <div class="item">
                            <div class="top">搜索点击指数</div>
                            <div class="text">{{infoDetail.search_click_index}}</div>
                        </div>
                        <div class="item">
                            <div class="top">关注人数</div>
                            <div class="text">{{infoDetail.follow_num}}</div>
                        </div>
                    </div>
                </div>
                <div class="line-item">
                    <div class="item-left">
                        <img :src="require('../../../../assets/images/student/good-icon-02.png')" alt="">
                        <div class="text">成交转化</div>
                    </div>
                    <div class="item-right">
                        <div class="item">
                            <div class="top">成交金额指数</div>
                            <div class="text">{{infoDetail.turnover_index}}</div>
                        </div>
                        <div class="item">
                            <div class="top">成交单量指数</div>
                            <div class="text">{{infoDetail.transaction_volume_index}}</div>
                        </div>
                        <div class="item">
                            <div class="top">成交转化率</div>
                            <div class="text">{{infoDetail.deal_conversion_rate}}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-content shadow-box">
            <div class="top-bar">
                <span class="text">趋势图</span>
            </div>
            <div class="trend-wrapper">
                <div class="trend-category">
                    <div class="cate-left">
                        <p>引流漏斗</p>
                        <p>成交转化</p>
                    </div>
                    <div class="cate-right">
                        <el-checkbox-group v-model="checkList" :max="4">
                            <el-checkbox :label="checkItem" v-for="checkItem in categoryList" :key="checkItem"></el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="choose-number">
                    已选择:
                    <span class="number">
                    <span>{{checkList.length}}</span> / 4
                </span>
                </div>
                <div class="trend-content" id="trendECharts"></div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import { stuOpStuOpDataCollectGoodsDetail } from '@/utils/apis.js'
    export default {
        data() {
            return {
                id: Number(this.$route.query.id) || null,
                op_id: Number(this.$route.query.op_id) || null,
                course_id: Number(this.$route.query.course_id) || null,
                infoDetail: {},
                checkList: ['访客指数', '成交金额指数', '成交单量指数', '成交转化率'],
                categoryList: ['访客指数', '搜索点击指数', '关注人数', '成交金额指数', '成交单量指数', '成交转化率'],
                trendECharts: null,
                allSeries: [],
            }
        },
        mounted() {
            this.getInfo()
            this.trendECharts = this.$echarts.init(document.getElementById('trendECharts'));
            this.getTrendECharts()

            let resizeTimer = null;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.trendECharts.resize()
                }, 1000)
            }
        },
        computed: {
            series() {
                return this.allSeries.filter(item => {
                    return this.checkList.includes(item.name)
                })
            }
        },
        watch: {
            series(val) {
                this.getTrendECharts()
            }
        },
        methods: {
            // 信息详情
            getInfo() {
                let params = {
                    id: this.id
                }
                if (this.op_id) {
                    params.op_id = this.op_id
                    params.course_id = this.course_id
                }
                stuOpStuOpDataCollectGoodsDetail(params).then((res) => {
                    this.infoDetail = res.data
                    this.infoDetail.visitor_index_data = this.changeData(res.data.visitor_index_data)
                    this.infoDetail.search_click_index_data = this.changeData(res.data.search_click_index_data)
                    this.infoDetail.follow_num_data = this.changeData(res.data.follow_num_data)
                    this.infoDetail.turnover_index_data = this.changeData(res.data.turnover_index_data)
                    this.infoDetail.transaction_volume_index_data = this.changeData(res.data.transaction_volume_index_data)
                    this.infoDetail.conversion_rate_data = this.changeData(res.data.conversion_rate_data)
                    this.getTrendSeries()
                })
            },
            // 将等于0的数值改为0.1，不然会出现数据无法显示 原因是：yAxis这个的type: 'log'
            changeData(data) {
                return data.map(item => {
                    return item === 0 ? 0.1 : item
                })
            },
            // 去商品详情
            toGoodDetail(id) {
                let routeDate = this.$router.resolve({
                    path: '/goods/detail',
                    query:{
                        g_id: id,
                        type: 1
                    }
                })
                window.open(routeDate.href, '_blank')
            },
            // 趋势图
            getTrendECharts() {
                this.trendECharts.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'log', // 这个设为log会导致数组中的值为0，会无法显示折线图，需将0改为0.1
                        axisLabel: {
                            show: false,
                            // textStyle: {
                            //     color: '#666'
                            // }
                            // formatter: function (value) {
                            //     return value === 0.1 ? 0 : value;
                            // }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#666',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                        formatter: (params) => {
                            let res = params[0].name;
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].seriesName === '成交转化率') {
                                    res += `<br/>${params[i].marker}${params[i].seriesName}:${params[i].value === 0.1 ? 0 : params[i].value}%`; // 将0.1的值转为0
                                } else {
                                    res += `<br/>${params[i].marker}${params[i].seriesName}:${params[i].value === 0.1 ? 0 : params[i].value}`; // 将0.1的值转为0
                                }
                            }
                            return res
                        }
                    },
                    legend: {
                        icon: 'circle',
                        // data: this.checkList,
                        bottom: 0,
                        selectedMode: false
                    },
                    grid: {
                        top: 10,
                        left: 10,
                        right: 20,
                        bottom: 80,
                    },
                    series: this.series
                }, true);
            },
            getTrendSeries() {
                this.allSeries = [
                    {
                        name: '访客指数',
                        type: 'line',
                        data: this.infoDetail.visitor_index_data,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#FFAC75",
                            }
                        },
                    },
                    {
                        name: '搜索点击指数',
                        type: 'line',
                        data: this.infoDetail.search_click_index_data,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#ffea00",
                            }
                        },
                    },
                    {
                        name: '关注人数',
                        type: 'line',
                        data: this.infoDetail.follow_num_data,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#1E88E5",
                            }
                        },
                    },
                    {
                        name: '成交金额指数',
                        type: 'line',
                        data: this.infoDetail.turnover_index_data,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#2DC079",
                            }
                        },
                    },
                    {
                        name: '成交单量指数',
                        type: 'line',
                        data: this.infoDetail.transaction_volume_index_data,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#4600d6",
                            }
                        },
                    },
                    {
                        name: '成交转化率',
                        type: 'line',
                        data: this.infoDetail.conversion_rate_data,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#E4393C",
                            }
                        },
                    },
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
            & > .el-scrollbar__view {
                /*min-width: 1400px;*/
            }
        }
    }
    .goods-info {
        display: flex;
        align-items: center;
        padding: 20px;
        margin: 20px;
        .good-img {
            width: 120px;
            height: 120px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                max-width: 100%;
            }
        }
        .info-right {
            width: 1%;
            flex: 1;
            margin-left: 16px;
            cursor: pointer;
            font-size: 18px;
        }
    }
    .shadow-box {
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, .05);
        border-radius: 10px;
        background: #fff;
    }
    .data-content {
        margin: 20px;
        padding: 20px;
        .top-bar {
            width: 218px;
            height: 50px;
            background: url("../../../../assets/images/student/customer-info.png") no-repeat;
            .text {
                padding-left: 70px;
                line-height: 50px;
                color: #fff;
                font-size: 20px;
            }
        }
        .line-data {
            display: flex;
            margin-top: 28px;
            border: 1px solid #E5E5E5;
            .line-item {
                width: 1%;
                flex: 1;
                display: flex;
                align-items: center;
                text-align: center;
                font-size: 16px;
                .item-left {
                    width: 112px;
                    height: 118px;
                    background: #F8F8F8;
                    img {
                        margin-top: 24px;
                    }
                    .text {
                        line-height: 1;
                        margin-top: 5px;
                    }
                }
                .item-right {
                    width: 1%;
                    flex: 1;
                    display: flex;
                    .item {
                        width: 33.333333%;
                        .text {
                            margin-top: 20px;
                            font-size: 18px;
                            line-height: 1;
                        }
                    }
                }
            }
        }
        .trend-wrapper {
            background: #fff;
            padding: 20px;
            .trend-category {
                display: flex;
                border: 1px solid #E5E5E5;
                .cate-left {
                    width: 120px;
                    background: #F8F8F8;
                    p {
                        line-height: 50px;
                        text-align: center;
                        font-size: 16px;
                        border-bottom: 1px solid #E5E5E5;
                        margin: 0;
                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                }
                .cate-right {
                    width: 1%;
                    flex: 1;
                    .el-checkbox-group {
                        display: flex;
                        flex-wrap: wrap;
                        .el-checkbox {
                            margin-right: 0;
                            line-height: 50px;
                            margin-left: 48px;
                            width: calc(15% - 48px);
                            box-sizing: border-box;
                            &:nth-of-type(3) {
                                width: calc(70% - 48px);
                            }
                            &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
                                &:after {
                                    content: '';
                                    position: absolute;
                                    left: -48px;
                                    right: 0;
                                    bottom: -1px;
                                    height: 1px;
                                    background: #E5E5E5;
                                }
                                /*border-bottom: 1px solid #E5E5E5;*/
                            }
                        }
                    }
                }
            }
            .choose-number {
                margin-top: 30px;
                font-size: 18px;
                .number {
                    margin-left: 20px;
                    span {
                        color: #1AB163;
                    }
                }
            }
            .trend-content {
                margin-top: 30px;
                height: 300px;
                width: 100%;
            }
        }
    }
</style>